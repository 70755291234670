.available-items {
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: calc(100vh - 200px);
  margin-right: 14px;
}
.available-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(202, 92, 92, 0.3) !important;
  background-color: #F5F5F5 !important;
  border-radius: 10px !important;
}
.available-items::-webkit-scrollbar {
  width: 5px !important;
  background-color: #F5F5F5 !important;
}