.faq .nav-item .nav-link {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    background: #F4F4F4;
    color: #373737;
    border-radius: 6px;
    margin: 5px 10px;
    padding: 7px 16px;
    cursor: pointer;
  }

  .faq .nav-item .nav-link.active{
    /* color: green; */
    color: #fff;;
    background: green;
    font-weight: 500;
  }