canvas {
  position: relative;
  z-index: 0;
}
.top_margindiv {
  margin-top: -65px !important;
}
.form-group.cust-form .customCountryCode {
  width: 80%;
}
.react-calendar {
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
  border: none !important;
}
.cust-form {
  display: flex;
}
.react-calendar abbr {
  color: #636161;
}
.react-calendar__tile--now {
  background: #fff !important;
}
.react-calendar__tile--active {
  background: #5eaf4c !important;
  border-radius: 4px;
}
.cust-form .merchant_select {
  width: auto;
}
.cust-form label {
  width: 100%;
}
.select-box11 {
  margin-right: 5px;
}
.cust-form .merchant_select {
  width: auto;
  padding: 0 8px;
  height: 50px;
}
.merchant-rev{
  height:332px;
}
.merchant_select {
  background: #fff !important;
}
.custom-select-wrap.full.large.alltime_maincontainer {
  position: relative !important;
  margin-left: 20px;
}
.alltime_maincontainer .fa {
  position: absolute;
  color: #81868b;
  margin-top: 10px;
  right: 10px;
  font-size: 20px;
}

.available-items {
  overflow-x: hidden;
  overflow-y: auto !important;
  max-height: calc(100vh - 200px);
  margin-right: 14px;
}
.available-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(202, 92, 92, 0.3) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.available-items::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5 !important;
}
.modal_height {
  height: 530px;
}
.cancel_btn {
  color: #373737 !important;
  background: #dadada !important;
}
.nav-tabs {
  margin-bottom: 20px;
}
.nav-tabs .nav-item {
  margin-bottom: 0 !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 1px solid #51a55d;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: none;
}
.nav-tabs .nav-link {
  /* border-bottom: 1px solid #51a55d; */
}
.tabs_switch .switch {
  position: relative;
  display: inline-block !important;
  width: 28px !important;
  height: 17px !important;
  display: inline-block;
}
.tabs_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.user-chart-level.multiple ul li {
  display: flex;
  height: 100%;
}
.user-chart-level .chart-level-bg.level-payment {
  height: 100%;
}
.chart-level-txt img {
  max-width: 30px;
  margin-left: 10px;
}
.tabs_switch input:checked + .slider {
  background-color: #499e68;
}
.tabs_switch input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}
.tabs_switch .slider.round {
  border-radius: 50px;
}
.tabs_switch .slider.round:before {
  border-radius: 50%;
}
.tabs_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e6e6e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tabs_switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.labelinputspan {
  display: inline-block;
  margin-right: 15px;
}
.merchantList li {
  margin-top: 10px !important;
}
.tab-content {
  width: 100%;
}
.customCountryCode {
  width: 100%;
}
.cust-form .css-yk16xz-control,
.cust-form .select-box11 .merchant_select,
.cust-form .customCountryCode input {
  height: 50px !important;
}
.cust-form .form-group textarea.form-control {
  height: 50px;
}
