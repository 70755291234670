.btnSet .fa-cog {
  position: relative;
  text-align: center;
  z-index: 999;
}
.btnSet img {
  position: absolute;
  left: 0;
}
.site-header .dropdown-menu {
  display: block;
  left: 39%;
  width: 210px;
  border-radius: 10px;
}
.site-header .dropdown-menu a {
  display: block;
  text-align: left;
  padding: 10px 0px 14px 20px;
}
a.accountsetting {
  color: #38b635;
}
a.adminmanagment {
  color: #000;
}
a.logout_btn {
  color: #6c757d !important;
}


@media (max-width: 768px) {
  .mobClick {
    display: flex;
  }
  

  .site-nav ul {
    flex-direction: column;
  }
  
}