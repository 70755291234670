.cms-heading {
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin: 0 -20px;
  /* padding-bottom: 20px; */
}

.cms-single {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(117, 119, 121, 0.1);
  border-radius: 6px;
  padding: 0px 40px 20px 40px;
}
.nav-item .nav-link{
  cursor: pointer;
}
.nav-item .nav-link.active{
  font-weight: 600;
  color: green;
}
.green-btn-privacy {
  background: linear-gradient(8.59deg, #499e68 20.71%, #66b644 93.67%);
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  /* position: relative; */
  /* float: right; */
  margin-right: 10px;
  margin-left: 10px;
  /* margin-bottom: 20px; */
}
